import React from 'react';
import { BsFillPencilFill, BsFillFileEarmarkPlusFill } from 'react-icons/bs';
import { getBase64Img, isGenericCylinder, parseObs } from '../../utils/utils';
import { getProductsImages } from '../../services/productService';
import { usePortalStore } from '../../store';
import { ImageSlider } from '../image/slider';
import { Spinner } from '../../base/Spinners';
import { PrimaryTitleGray } from '../../base/colors';
import { TriageField } from '../triage';
import { OrdersDataSource } from '../../datasources/OrdersDataSource';
import { Product } from '../../types/Product';
import { OrderTableData } from '../../types/Order';

type OrderDetailsProps = {
  order: OrderTableData;
  onOrderEdit?: any;
  uploadCECFile?: any;
  handleChangeCECFile?: any;
  hiddenFileInput?: any;
  isRowExpanded: boolean;
};

const CAT_ACCESSORIES = 4;

export const OrderDetails: React.FC<OrderDetailsProps> = ({
  order,
  onOrderEdit,
  uploadCECFile,
  handleChangeCECFile,
  hiddenFileInput,
  isRowExpanded,
}) => {
  // TODO: GET PRODUCT INFO
  const [products, setProducts] = React.useState<{
    accessories: Product[];
    equipments: Product[];
  }>({
    accessories: [],
    equipments: [],
  });
  const [images, setImages] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const currentUser = usePortalStore((state: any) => state.currentUser);
  const [isGenericOrder, setIsGenericOrder] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    if (isRowExpanded) {
      getOrderProductDetails();
    }
  }, [isRowExpanded]);

  const getOrderProductDetails = async () => {
    try {
      const productsResponse =
        await new OrdersDataSource().getOrderProductDetails(order.number);

      const modifiedProductsResponse = productsResponse.map((product) => {
        if (product.code === '85000378') {
          setIsGenericOrder(true);
          return { ...product, name: 'genérico' };
        }
        return product;
      });

      const newProducts = {
        accessories: modifiedProductsResponse.filter(
          (product) => product.category_id === CAT_ACCESSORIES
        ),
        equipments: modifiedProductsResponse.filter(
          (product) => product.category_id !== CAT_ACCESSORIES
        ),
      };

      setProducts(newProducts);

      const imagesResponse = await getProductsImages([
        ...modifiedProductsResponse.map((product) => product.code),
      ]);

      setImages(imagesResponse);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  if (products.equipments.length === 0 && products.accessories.length === 0) {
    return <></>;
  }
  if (loading) {
    return (
      <div
        className="box-expand-panel"
        style={{
          display: 'block',
          boxSizing: 'border-box',
          textAlign: 'center',
          padding: '50px',
        }}
      >
        <Spinner></Spinner>
      </div>
    );
  }
  return (
    <div
      className="table-expand"
      style={{ display: 'block', boxSizing: 'border-box' }}
    >
      <div
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          alignItems: 'stretch',
        }}
      >
        <div className="image-equipment box-expand-panel">
          <div className="img-equip">
            <ImageSlider
              images={images.map(getBase64Img)}
              style={null}
              maxHeight={null}
              hideNoImage={null}
            ></ImageSlider>
          </div>
        </div>
        <div className="box-expand-panel" style={{ display: 'block' }}>
          <div className="equipment-name">
            {isGenericOrder
              ? 'Validação e Processamento de pedido'
              : products.equipments.length === 0
              ? ''
              : products.equipments.length > 1
              ? 'Equipamentos'
              : 'Equipamento'}
          </div>
          {products.equipments.map(
            (equipment: any, idxEq: number) =>
              // Removendo equipamento trocado da visualização
              !equipment.is_exchanged_product &&
              !isGenericCylinder(equipment) && (
                <div
                  key={idxEq}
                  style={{
                    minWidth: '300px',
                    color: 'black',
                    boxSizing: 'border-box',
                    paddingTop: '15px',
                  }}
                >
                  <li style={{ fontSize: '16px' }}>
                    <span style={{ fontWeight: '700' }}>
                      {currentUser.canSeeFullOrdersTable
                        ? `${equipment.code} `
                        : ''}
                    </span>
                    {equipment.code !== '85000378' &&
                      !isGenericCylinder(equipment) && (
                        <>
                          {equipment.quantity_volume ?? equipment.quantity}x{' '}
                          {equipment.name ?? equipment.subCategory}{' '}
                        </>
                      )}
                  </li>
                </div>
              )
          )}
          {products.equipments.length === 0 ? (
            ''
          ) : (
            <React.Fragment>
              <br />
              <br />
            </React.Fragment>
          )}
          <div className="equipment-name">
            {products.accessories.length === 0
              ? ''
              : products.accessories.length > 1
              ? 'Descartáveis'
              : 'Descartável'}
          </div>
          {products.accessories.map((c: any, idx: number) => (
            <div
              key={idx}
              style={{
                minWidth: '300px',
                color: 'black',
                boxSizing: 'border-box',
                paddingTop: '15px',
              }}
            >
              <li style={{ fontSize: '16px' }}>
                <span style={{ fontWeight: '700' }}>
                  {currentUser.canSeeFullOrdersTable ? `${c.code}: ` : ''}
                </span>
                {c.quantity}x {c.name}
              </li>
            </div>
          ))}
          <br />
          {order.obs && order.obs.trim() !== '' ? (
            <>
              <div className="equipment-name">Observação</div>
              <div className="description">{parseObs(order.obs, null)}</div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          className="box-expand-panel"
          style={{
            color: 'black',
            boxSizing: 'border-box',
            paddingLeft: '15px',
            paddingRight: '15px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              padding: '0px 10px',
            }}
          >
            {onOrderEdit ? (
              <BsFillPencilFill
                style={{
                  fontSize: '14px',
                  cursor: 'pointer',
                  color: PrimaryTitleGray,
                  display: 'block',
                  margin: '0px 10px',
                }}
                onClick={() => onOrderEdit(order)}
              ></BsFillPencilFill>
            ) : (
              <></>
            )}

            {uploadCECFile ? (
              <div style={{ margin: '0px 10px' }}>
                <input
                  type="file"
                  onChange={handleChangeCECFile}
                  style={{ display: 'none' }}
                  ref={hiddenFileInput}
                  multiple
                />
                <BsFillFileEarmarkPlusFill
                  style={{
                    fontSize: '14px',
                    cursor: 'pointer',
                    color: PrimaryTitleGray,
                    display: 'block',
                  }}
                  onClick={() => uploadCECFile(order)}
                ></BsFillFileEarmarkPlusFill>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="equipment-name">Endereço</div>
          <div className="description">
            {order.customer.address.name}
            {order.customer.address.complement !== ''
              ? ' - ' + order.customer.address.complement
              : ''}
            , {order.customer.address.number}. {order.customer.address.city}/
            {order.customer.address.uf}. CEP: {order.customer.address.zipcode}
          </div>
          {currentUser.isWhiteMartins || currentUser.isAdmin ? (
            <TriageField
              orderId={order.id}
              autoUpdate={false}
              height={150}
              inputField={false}
              showTitle={true}
              open={true}
            ></TriageField>
          ) : (
            <></>
          )}
          <br />
          {currentUser.canSeeFullOrdersTable ? (
            <>
              <div className="equipment-name">Informações</div>
              <div className="description">
                Pedido criado por:
                <span style={{ fontWeight: '700' }}>{order.created_by}</span>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
