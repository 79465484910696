import { Api } from '../api';

type RechargeList = {
  client_code: number,
  client_name: string,
  receiving_code: string,
  receiving_name: string,
  cpf: string,
  cnpj: string,
  ROWS: number,
}

export class RechargeDataSource {

  async getRecharge(page: number, pageSize: number, cnpj?:string|undefined, cpf?:string|undefined) {
    const result = (await new Api().fetch(`/ballast/rechargeList/cnpj/${cnpj}/cpf/${cpf}/page/${page}/pageSize/${pageSize}`)) as RechargeList
    return result;
  }
}
